import { reactive, watchEffect } from 'vue';
import { LOCALSTORAGE_KEY } from "@/custom_config";

// Crea uno stato reattivo per l'utente
const state = reactive({
    user: JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)) || null
});

export function setCurrentUser(user) {
    state.user = user;
}

export function clearUser() {
    state.user = null;
}

export function getUser() {
    return state.user;
}


// Watch per le modifiche dello stato e aggiorna il localStorage
/* watchEffect(() => {
    if (state.user) {
        localStorage.setItem(`${LOCALSTORAGE_KEY}`, JSON.stringify(state.user));
    } else {
        console.log('Rimozione state.user');
        localStorage.removeItem(`${LOCALSTORAGE_KEY}`);
    }
  }); */